import React, { useState, useEffect, } from "react";
import { Skeleton, Button, message } from "antd";
import "./RoomPrice.css";
import { _defaultHeaders, getCurrency } from "helpers/AxiosHelper";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import SaveRoomModal from "components/Hotel/SaveRoomModal/SaveRoomModal";
import { temporaryUserState } from "recoil/atoms"
import { useRecoilValue } from "recoil";
import CheckIndosatTeamMemberRolesHelper from "helpers/CheckIndosatTeamMemberRolesHelper";

const commas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const RoomPrice = (props) => {
  const [isOpenSaveBookingModal, setIsOpenSaveBookingModal] = useState(false);
  const [saveBookingRoomType, setSaveBookingRoomType] = useState(null);

  const [currency, setCurrency] = useState(props.currency);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [rates, setRates] = useState(props.intialData.rates);
  const [rooms, setRooms] = useState(props.intialData.room);
  const [savedBookingInfo, setSavedBookingInfo] = useState(null);

  useEffect(() => {
    document.addEventListener("keyup", handleKeyUp);

    if (props.recommendationId) {
      setData({
        hotel: {
          id: '',
          rates: rates,
          rooms: [rooms]
        }
      });
      setRates(props.recommendationData?.rates ?? rates);
      setRooms(props.recommendationData?.rooms ?? rooms);
    }

    getSavedBookingInfo();
    setLoading(false);

    return () => {
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  const handleKeyUp = (event) => {
    if (event.ctrlKey && event.keyCode === 75) {
      window.showProvider = true;
      const els = document.getElementsByClassName("recomendation-name");
      for (const el of els) {
        el.style.display = "block";
      }
    } else {
      if (window.showProvider) {
        window.showProvider = false;
        const els = document.getElementsByClassName("recomendation-name");
        for (const el of els) {
          el.style.display = "none";
        }
      }
    }
  };

  const onClick = async (totalRate, onlyPolicy = false) => {
    setLoading(true);

    props.handleSelection(
      props.recommendationId,
      props.token,
      totalRate,
      data.currency,
      data,
      false,
    );

    setLoading(false);
  };

  const queryString = useHistory()?.location?.search
  const queryParams = {};
  queryString.slice(1).split('&').forEach(query => {
    const [key, value] = query.split('=');
    queryParams[key] = value;
  });
  const search_timestamp = queryParams.d;
  const place_id = queryParams.p_id;
  const saved_booking_id = queryParams.saved_booking_id;

  const getSavedBookingInfo = async () => {
    const userData = JSON.parse(localStorage.getItem("userdata"));
    if (!userData) return;

    const companyData = JSON.parse(localStorage.getItem("companyData"));

    let data = props.recommendationData.data.hotel;
    const config = {
      method: "get",
      url: `/saved-booking-detail/get-detail?companyId=${companyData?.id}&userId=${userData?.id}&hotel_id=${data?.id}&place_id=${place_id}&search_timestamp=${search_timestamp}`,
    };

    await Axios(config)
      .then((response) => {
        if (response?.data) setSavedBookingInfo(response.data)
        // message.success("Booking details has successfully been saved! Please wait for the PIC to book for you.");
      })
      .catch((err) => {
        // alert(
        //   `Failed to save booking`
        // );
      })
      .finally(() => {
        // setIsLoading(false)
      });
  };

  const handleSaveBooking = async () => {
    const userData = JSON.parse(localStorage.getItem("userdata"));
    const companyData = JSON.parse(localStorage.getItem("companyData"));

    // Add Axios call to save booking here
    // Update the state with the response

    let locState = props.carryForward;
    const hotelAddress = props.hotelInfo.contact.address
    const locationInfo = props.location.state
    // console.log(props.hotelInfo)
    // return
    const config = {
      method: "post",
      url: 'saved-booking-detail/save',
      headers: _defaultHeaders(
        getCurrency(),
        props.carryForward.correlationId
      ),
      data: {
        companyId: companyData.id,
        teamMemberId: '1',
        userId: userData.id,

        correlationId: locationInfo.correlationId,
        latitude: locationInfo.lattitude,
        longitude: locationInfo.longittude,
        place: locationInfo.place,

        search_timestamp,
        place_id,
        hotel_id: props.hotelInfo.id,
        hotel_name: props.hotelInfo.name,
        hotel_image: props.hotelInfo.heroImage,

        destination: `${hotelAddress.city.name}, ${hotelAddress.country.name}`,
        checkin_date: locState.startDate,
        checkout_date: locState.endDate,
        notes: saveBookingRoomType,
        guest_details: JSON.stringify({
          noofAdults: locState.noofAdults,
          noofChild: locState.noofChild,
          panes: locState.panes,
          ages: props.childrenInfoString(true),
        }),
        room_count: locState.noofroom,
      },
    };

    await Axios(config)
      .then((response) => {
        getSavedBookingInfo()
        message.success("Booking details has successfully been saved! Please wait for the PIC to book for you.");
        setTimeout(() => {
          window.location.reload()
        }, 1000);
      })
      .catch((err) => {
        console.error(err)
        // alert(
        //   `Failed to save booking`
        // );
      })
      .finally(() => {
        // setIsLoading(false)
      });
  };


  const removeBooking = async ({
    companyId,
    userId,
    hotel_id,
    place_id,
  }) => {
    const config = {
      method: 'post',
      url: 'saved-booking-detail/remove',
      headers: _defaultHeaders(getCurrency(), props.carryForward.correlationId),
      data: {
        companyId,
        userId,
        hotel_id,
        place_id,
      },
    };

    await Axios(config)
      .then((response) => {
        getSavedBookingInfo();
        message.success('Booking has been successfully removed.');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        console.error('Error removing booking:', err);
        message.error('Failed to remove booking.');
      })
      .finally(() => {
        // Perform any additional cleanup actions here if needed
      });
  };


  const generateRoomDetail = (rooms) => {
    let roomName = '';
    rooms.forEach((room, index) => {
      const len = index + 1;
      roomName += room.name;

      if (len < rooms.length)
        roomName += ' + ';
    });

    return roomName.toLowerCase().replace(/\b[a-z]/g, function (letter) {
      return letter.toUpperCase();
    });
  };

  const temporaryUser = useRecoilValue(temporaryUserState)

  // let isAllowedToBookIndosatB2B = CheckIndosatTeamMemberRolesHelper(1002)
  // useEffect(() => {
  //   isAllowedToBookIndosatB2B = CheckIndosatTeamMemberRolesHelper(1002)
  // }, [temporaryUser])

  const generatePrice = (rates) => {
    let rate = 0;
    rates.forEach(r => {
      rate += parseFloat(r.totalRate);
    });

    rate = Math.ceil(rate).toFixed(2);

    return (
      <div>
        <SaveRoomModal
          isOpen={isOpenSaveBookingModal}
          setIsOpen={setIsOpenSaveBookingModal}
          roomType={saveBookingRoomType}
          setRoomType={setSaveBookingRoomType}
          submitAction={handleSaveBooking}
          currentData={savedBookingInfo}
          removeBooking={removeBooking}
        />
        <div style={{ height: "3vh" }}></div>
        <div className="roomSelect">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ color: "#858586" }}>Price</div>
            <div className="costRoom">
              {currency + " "}
              {commas(rate)}
            </div>
          </div>

          <Button
            style={{
              display: props.isAllowedToBookIndosatB2B ? 'block' : 'none'
            }}
            type="primary" disabled={loading} onClick={() => onClick(rate, false)}>
            {loading ? "Final Price Checking..." : "Select"}
          </Button>
        </div>
      </div >
    );
  };

  const checkRefundablePolicy = (rates) => {
    let rate = 0;
    rates.forEach(r => {
      rate += parseFloat(r.totalRate);
    });

    rate = Math.ceil(rate).toFixed(2);

    props.handleSelection(
      props.recommendationId,
      props.token,
      rate,
      data.currency,
      data,
      true,
    );
  };

  if (!rates || !Array.isArray(rates) || !Array.isArray(rooms)) return <></>;

  const isRefundable = rates[0].refundability === 'Refundable';
  const supplier = rates[0].providerName ?? 'Unknown';

  const boardBasisGenerate = () => {
    let boardBasis;
    switch (rates[0].boardBasis.type) {
      case "BedAndBreakfast":
        boardBasis = 'Breakfast Included';
        break;
      case "Other":
      case "RoomOnly":
        boardBasis = undefined;
        break;
      default:
        boardBasis = rates[0].boardBasis.description || rates[0].boardBasis.type;
        break;
    }

    if (!boardBasis) return <></>;

    return (
      <div className="blackroomType">
        {boardBasis}
      </div>
    );
  };

  return (
    <div className="RoomPriceContainer" key={props.recommendationId}>
      <span className="recomendation-name" style={{ display: "none" }}>
        Corelation : {props.location.state.correlationId}
      </span>
      <span className="recomendation-name" style={{ display: "none" }}>
        Recomendation : {props.recommendationId}
      </span>
      <div className="recomendation-name" style={{ display: "none", backgroundColor: 'red', color: 'white' }}>
        {supplier}
      </div>
      <div className="horizontal">
        {boardBasisGenerate()}
        {isRefundable && (
          <div onClick={() => checkRefundablePolicy(rates)} className="roomPriceIconBox">
            <div className="blackroomType">
              Refundable
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M11 9h2V7h-2m1 13c-4.41 0-8-3.59-8-8s3.59-8 8-8s8 3.59 8 8s-3.59 8-8 8m0-18A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2m-1 15h2v-6h-2z" /></svg>
          </div>
        )}
      </div>

      {Array.isArray(rooms) && generateRoomDetail(rooms)}
      {loading && (
        <Skeleton paragraph={{ rows: 1, width: ["75%"] }} active />
      )}

      {!loading && generatePrice(rates)}
    </div>
  );
};

export default RoomPrice;
