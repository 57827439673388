import React, { useEffect, useState } from "react";
import { Button, Icon, message, Rate } from "antd";
import { HotelDescription } from "components/Hotel/HotelDescription/HotelDescription";
import { Link } from "react-router-dom";
import ImageGallery from "react-image-gallery";
import moment from "moment";
import FooterPage from "components/Global/FooterPage/FooterPage";
import HotelLocation from "components/Hotel/HotelLocation/HotelLocation";
import Landmarks from "components/Hotel/Landmarks/Landmarks";

import { getCurrency, _defaultHeaders } from "helpers/AxiosHelper";
import RoomsAndRates from "components/Hotel/RoomsAndRates/RoomsAndRates";
import SaveRoomModal from "components/Hotel/SaveRoomModal/SaveRoomModal";

import Axios from "axios";
import { FaStar } from "react-icons/fa";

const ShowMore = ({ state, setState }) => (
  <Link
    to="#"
    onClick={(e) => {
      e.preventDefault();
      setState(!state);
    }}
    style={{ color: "#F48247" }}
  >
    {state ? "View less" : "Show more"}
  </Link>
);

export const AccommodationDetailsMobile = (props) => {
  const [about, setabout] = useState(false);
  const [viewBookingSummary, setviewBookingSummary] = useState(false);
  const { data } = props;
  const { images, name, contact, starRating } = data;

  const [isLoading, setIsLoading] = useState(false);
  const [savedBookingInfo, setSavedBookingInfo] = useState(null)
  const [saveBookingRoomType, setSaveBookingRoomType] = useState(null);
  const [isOpenSaveBookingModal, setIsOpenSaveBookingModal] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userdata"));
  const companyData = JSON.parse(localStorage.getItem("companyData"));
  const queryString = props?.history?.location?.search;
  const queryParams = {};
  queryString.slice(1).split('&').forEach(query => {
    const [key, value] = query.split('=');
    queryParams[key] = value;
  });
  const search_timestamp = queryParams.d;
  const place_id = queryParams.p_id;
  const saved_booking_id = queryParams.saved_booking_id;

  const days = Math.round(
    (Date.parse(props.location.state.endDate) -
      Date.parse(props.location.state.startDate)) /
    (1000 * 60 * 60 * 24)
  );

  const imagesArray = images.map((image) => {
    return {
      original:
        image.links[1] && image.links[1].url
          ? image.links[1].url
          : image.links[0].url,
      thumbnail: image.links[0].url,
      description: image.caption,
    };
  });


  const fetchSavedBookingData = async () => {
    if (saved_booking_id) {
      const config = {
        method: "get",
        url: `/saved-booking-detail/${saved_booking_id}/get-detail`,
      };

      await Axios(config)
        .then((response) => {
          const guestDetails = JSON.parse(response.data.guest_details)
          setSavedBookingInfo({
            ...response.data, guestDetails
          })

          props.location.state.startDate = response.data.checkin_date
          props.location.state.endDate = response.data.checkout_date
          props.location.state.totalRooms = response.data.room_count
          props.location.state.panes = guestDetails.panes
          props.location.state.totalAdults = guestDetails.noofAdults
          props.location.state.totalChildren = guestDetails.noofChild
        })
        .catch((err) => {
          // alert(
          //   `Failed to save booking`
          // );
        })
        .finally(() => {
          setIsLoading(false)
        });
    }
  }

  const handleSaveBooking = async () => {
    const locationInfo = props.location.state
    // const state = {
    //   correlationId: props.history.correlationId,
    //   place: props.place,
    //   placeId: props.placeId,
    //   activeSuggestion: props.activeSuggestion,
    //   startDate: props.startDate, //
    //   endDate: props.endDate, //
    //   noofAdults: props.noofAdults, //
    //   noofChild: props.noofChild, // 
    //   noofroom: props.noofroom, //
    //   lattitude: props.geoCode.lat,
    //   longittude: props.geoCode.long,
    //   panes: props.location.panes,
    // };
    const userData = JSON.parse(localStorage.getItem("userdata"));
    const companyData = JSON.parse(localStorage.getItem("companyData"));

    const hotelAddress = props.data.contact.address
    let locState = props.carryForward;


    let data = props.data;
    const config = {
      method: "post",
      url: 'saved-booking-detail/save',
      headers: _defaultHeaders(
        getCurrency(),
        props.carryForward.correlationId
      ),
      data: {
        companyId: companyData.id,
        teamMemberId: '1',
        userId: userData.id,

        correlationId: locationInfo.correlationId,
        latitude: locationInfo.lattitude,
        longitude: locationInfo.longittude,
        place: locationInfo.place,

        search_timestamp,
        place_id,
        hotel_id: data.id,
        hotel_name: props.data.name,
        hotel_image: props.data.heroImage,

        destination: `${hotelAddress.city.name}, ${hotelAddress.country.name}`,
        checkin_date: locState.startDate,
        checkout_date: locState.endDate,
        notes: saveBookingRoomType,
        guest_details: JSON.stringify({
          noofAdults: locState.noofAdults,
          noofChild: locState.noofChild,
          panes: locState.panes,
          ages: props.childrenInfoString(true),
        }),
        room_count: locState.noofroom,
      },
    };

    await Axios(config)
      .then((response) => {
        getSavedBookingInfo()
        setSaveBookingRoomType(null)
        setIsOpenSaveBookingModal(false)
        message.success("Booking details has successfully been saved! Please wait for the PIC to book for you.");
        setTimeout(() => {
          window.location.reload()
        }, 1000);
      })
      .catch((err) => {
        // alert(
        //   `Failed to save booking`
        // );
      })
      .finally(() => {
        // setIsLoading(false)
      });

  }

  const removeBooking = async ({
    companyId,
    userId,
    hotel_id,
    place_id,
  }) => {
    const config = {
      method: 'post',
      url: 'saved-booking-detail/remove',
      // headers: _defaultHeaders(getCurrency(), props.carryForward.correlationId),
      data: {
        companyId,
        userId,
        hotel_id,
        place_id,
      },
    };

    await Axios(config)
      .then((response) => {
        getSavedBookingInfo();
        message.success('Booking has been successfully removed.');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        console.error('Error removing booking:', err);
        message.error('Failed to remove booking.');
      })
      .finally(() => {
        // Perform any additional cleanup actions here if needed
      });
  };


  const getSavedBookingInfo = async () => {
    if (!userData) return;

    let data = props.data;
    const config = {
      method: "get",
      url: `/saved-booking-detail/get-detail?companyId=${companyData?.id}&userId=${userData?.id}&hotel_id=${data?.id}&place_id=${place_id}&search_timestamp=${search_timestamp}`,
    };

    await Axios(config)
      .then((response) => {
        if (response?.data) {
          const guestDetails = JSON.parse(response.data.guest_details)

          setSavedBookingInfo({
            ...response.data, guestDetails
          })
          // setSavedBookingInfo(response.data)
        }
        // message.success("Booking details has successfully been saved! Please wait for the PIC to book for you.");
      })
      .catch((err) => {
        // alert(
        //   `Failed to save booking`
        // );
      })
      .finally(() => {
        // setIsLoading(false)
      });
  }

  useEffect(() => {
    getSavedBookingInfo()
    fetchSavedBookingData()
  }, [])

  return (
    <div className="AccommodationDetailsMobile">
      <SaveRoomModal
        isOpen={isOpenSaveBookingModal}
        setIsOpen={setIsOpenSaveBookingModal}
        roomType={saveBookingRoomType}
        setRoomType={setSaveBookingRoomType}
        submitAction={handleSaveBooking}
        hotelDatas={props.data}
        currentData={savedBookingInfo}
        removeBooking={removeBooking}
      // getSavedBookingInfo={getSavedBookingInfo}
      // childrenInfoString={props.childrenInfoString}
      // data={props.data}
      // carryForward={props.carryForward}
      />

      {props.header}

      {props.bookingModal}
      {props.policyModal()}

      <div style={{ marginTop: '40px' }}>
        <ImageGallery
          items={imagesArray}
          showFullscreenButton={false}
          showPlayButton={false}
          showThumbnails={false}
          showNav={true}
          disableSwipe={false}
          showBullets={false}
        />
      </div>

      <div className="information">
        <div style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          columnGap: '20px'
        }}>
          <div className="mainHeading">{name}</div>

          {companyData && !saved_booking_id && (
            <Button style={{
              backgroundColor: (savedBookingInfo == null) ? 'grey' : 'red',
            }} className={'roundedButton'}
              onClick={() => setIsOpenSaveBookingModal(true)}
            >
              <FaStar style={{
                color: 'white',
                marginLeft: '-7px',
              }} />
            </Button>
          )}

        </div>

        <div className="grayInfo">
          {contact.address.city.name}, {contact.address.country.name}
        </div>
        <div>
          <div className="roomInfo">
            <div className="SearchResultsRoomType">{data.type}</div>
            <Rate
              disabled={true}
              value={Number(starRating)}
              className="Searchstars"
            />
          </div>

          <div style={{ marginTop: "1rem" }}>
            <div className="subHeading">About the Hotel</div>
            <div
              className="grayInfo"
              style={
                about
                  ? { height: "auto" }
                  : { height: "15rem", overflow: "hidden" }
              }
            >
              <HotelDescription descriptions={data.descriptions} />
            </div>
            {<ShowMore state={about} setState={setabout} />}
          </div>

          <div style={{ marginTop: "1rem" }}>
            <div className="subHeading">Location and Landmarks</div>

            <Landmarks nearByAttractions={data.nearByAttractions} number={4} />
          </div>

          <div style={{ marginTop: "1rem" }}>
            <HotelLocation
              name={name}
              geoCode={data.geoCode}
              address={data.contact.address}
            />
          </div>

          <div style={{ marginTop: "1rem" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "2rem",
              }}
            >
              <div className="subHeading" style={{ margin: 0 }}>
                Booking details
              </div>

              <Icon
                type={viewBookingSummary ? "minus" : "plus"}
                onClick={() => setviewBookingSummary(!viewBookingSummary)}
              />
            </div>

            {viewBookingSummary && (
              <>
                <div style={{ margin: "1rem 0" }}>
                  Details for{" "}
                  <b>
                    {props.location.state.noofroom}{" "}
                    {props.location.state.noofroom === 1 ? " room" : " rooms"},{" "}
                    {days} nights
                  </b>{" "}
                  and{" "}
                  <b>
                    {props.location.state.noofAdults} Adult
                    {props.location.state.noofAdults > 1 && "s"}{" "}
                    {props.location.state.noofChild > 0 &&
                      (props.location.state.noofChild === 1
                        ? "and 1 child"
                        : `and ${props.location.state.noofChild} children`)}
                  </b>
                  {props.childrenInfoString() && (
                    <div>{props.childrenInfoString()}</div>
                  )}
                </div>

                <div style={{ padding: "1rem", border: "1px solid #E9EDEF" }}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>Check in:</div>
                    <div>
                      {moment(props.location.state.startDate)
                        .format("ddd")
                        .toString()}
                      , {props.location.state.startDate}
                    </div>
                    <div>{data.checkinInfo.beginTime}</div>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>Check out:</div>
                    <div>
                      {moment(props.location.state.endDate)
                        .format("ddd")
                        .toString()}
                      , {props.location.state.endDate}
                    </div>
                    <div>{data.checkoutInfo.time}</div>
                  </div>
                </div>
              </>
            )}
          </div>

          <RoomsAndRates
            hotelId={data.id}
            handleSelection={props.handleSelection}
            selected={props.selectedToken}
            setPaymentData={props.setpaymentData}
            location={props.location}
            openPolicyModal={props.openPolicyModal}
            setModal={props.setModal}
          />
        </div>
      </div>

      <FooterPage />
    </div>
  );
};
