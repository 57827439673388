import React, { useState, useEffect, } from "react";
import {
  Form,
  Input,
  Button,
  InputNumber,
  Checkbox,
  Icon,
  Spin,
  Modal,
} from "antd";
import "./PersonalDetails.css";
import Header from "../../components/Header/Header";
import CountrySelector from "pages/PersonalDetails/CountrySelector";
import { RegionSelector } from "./RegionSelector";
import { usePaymentInputs } from "react-payment-inputs";
import MediaQuery, { useMediaQuery } from "react-responsive";
import Axios from "axios";
import { _defaultHeaders, getCurrency } from "helpers/AxiosHelper";
import PhoneInput from "react-phone-input-2";
import moment from "moment";
import { _gtag, formatPolicyText, formatPolicyType, getIsTestAcc, processCancellationPolicy } from "helpers/GlobalHelper"
import { Elements, useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckIndosatTeamMemberRolesHelper from "../../helpers/CheckIndosatTeamMemberRolesHelper";

import FooterPage from "components/Global/FooterPage/FooterPage";

const CheckoutPageForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const userData = JSON.parse(localStorage.getItem("userdata"));
  const companyData = JSON.parse(localStorage.getItem("companyData"));

  const queryString = props?.history?.location?.search;
  const queryParams = {};
  queryString.slice(1).split('&').forEach(query => {
    const [key, value] = query.split('=');
    queryParams[key] = value;
  });
  const saved_booking_id = queryParams.saved_booking_id;


  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 775px)",
  });

  const isMobile = useMediaQuery({
    maxWidth: 775
  })

  let creditCardRequired = true;
  const rateId = [];
  const roomAllocation = [];
  const corelationId = props.location.state.recommendationData.correlationId;
  const selectedToken = props.location.state.selectedToken
  const token = props.location.state.token;
  // const token = props.location.state.recommendationData.token;
  const hotelId = props.location.state.recommendationData.hotel.id;
  const rates = props.location.state.recommendationData.hotel.rates;
  const guestDetail = props.location.state.guestDetails;

  rates.forEach((rt) => {
    if (rt.cardRequired) {
      creditCardRequired = true;
    }
  });

  const [sameAddress, setsameAddress] = useState(true);
  const [termsAndConditions, settermsAndConditions] = useState(false);
  const [viewMore, setviewMore] = useState(isDesktopOrLaptop ? true : false);
  const [isLoading, setIsLoading] = useState(false);
  const [cardErrorMsg, setCardErrorMsg] = useState(null);
  const [cardValidation, setCardValidation] = useState(false);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(null)
  const [savedBookingInfo, setSavedBookingInfo] = useState(null)

  const [policyModalVisible, setpolicyModalVisible] = useState(false);
  const { getFieldDecorator, getFieldValue, setFieldsValue, } = props.form;

  const {
    meta,
  } = usePaymentInputs();

  const { hasProvider, config: pConfig } = JSON.parse(localStorage.getItem(process.env.REACT_APP_ENV + '_VD_PROVIDER')) ?? { hasProvider: false, config: {} }

  const initPaymentValue = {
    phone: pConfig.name == 'indosat' || pConfig.name == 'business-dashboard' || pConfig.name == 'business' ? '62' : '',
    age: '',
    address: '',
    code: '',
    city: '',
    country: pConfig.name == 'indosat' || pConfig.name == 'business-dashboard' || pConfig.name == 'business' ? 'ID' : '',
    state: ''
  }
  // const initPaymentValue = {
  //   phone: '6283403049034',
  //   // phone: pConfig.name == 'indosat' || pConfig.name == 'business-dashboard' || pConfig.name == 'business' ? '62' : '',
  //   age: 20,
  //   address: 'Jl. abc',
  //   code: '30493',
  //   city: '',
  //   country: 'ID',
  //   // country: pConfig.name == 'indosat' || pConfig.name == 'business-dashboard' || pConfig.name == 'business' ? 'ID' : '',
  //   state: 'Kota 123'
  // }

  const autoSetCountryName = async (phoneData, country) => {
    if (country) {
      props.form.setFieldsValue({
        country: country?.countryCode?.toUpperCase(),
        state: undefined,
      });
    }
  }

  const PaymentInit = async (values) => {

    _gtag('begin_checkout');

    const roomOccupantDetail = guestDetail.panes[0]

    const leadGuestInfo = {
      type: "Adult",
      title: values.title,
      firstName: values.firstName,
      lastName: values.lastName,
      middleName: "",
      suffix: "",
      age: values.age,
      email: values.email,
    }

    rates.forEach((rt, index) => {

      const guest = [leadGuestInfo]
      if (roomOccupantDetail[index].children > 0) {
        guest.push({
          type: "child",
          age: roomOccupantDetail[index].childrenInfo[0]
        })
      }

      roomAllocation.push({
        roomId: rt.occupancies[0].roomId,
        rateId: rt.id,
        guests: guest,
      });

      rateId.push(rt.id);
    });

    let currency = getCurrency();
    let useremail = localStorage.getItem("useremail");

    console.log(props.location.state, {
      booking_type: saved_booking_id ? 'B2B_INDOSAT' : 'B2C',
      saved_booking_id,
      stripe_id: null,
      useremail,
      currency,
      hotelInfo: {
        checkinDate: props.location.state.checkinDate,
        checkoutDate: props.location.state.checkoutDate,
        roomInfo: {
          count: props.location.state.Roomcount,
          guestDetails: props.location.state.guestDetails,
          guestDetailsPerRoom: guestNames.map((guestList) => guestList).join(',')
        },
        destination: props.location.state.destination,
        price: props.location.state.rateDetails,
        rates: props.location.state.recommendationData?.hotel?.rates,
        address: props.location.state.hotelAddress,
        image: props.location.state.hotelImage,
        hotelname: props.location.state.hotelname,
        token,
        selectedToken,
        id: props.location.state?.hotelid,
        // id: props.location.state.recommendationData?.hotel?.id,
        bookingInfo: {
          billingContact: values,
          rateIds: rateId,
          roomsAllocations: roomAllocation,
        }
      },
    },
    )
    // return

    let config = {
      method: "post",
      url: 'payment/checkout',
      headers: _defaultHeaders(currency, corelationId),
      data: {
        booking_type: saved_booking_id ? 'B2B_INDOSAT' : 'B2C',
        saved_booking_id,
        stripe_id: null,
        useremail,
        currency,
        hotelInfo: {
          checkinDate: props.location.state.checkinDate,
          checkoutDate: props.location.state.checkoutDate,
          roomInfo: {
            count: props.location.state.Roomcount,
            guestDetails: props.location.state.guestDetails,
            guestDetailsPerRoom: guestNames.map((guestList) => guestList).join(',')
          },
          destination: props.location.state.destination,
          price: props.location.state.rateDetails,
          rates: props.location.state.recommendationData?.hotel?.rates,
          address: props.location.state.hotelAddress,
          image: props.location.state.hotelImage,
          hotelname: props.location.state.hotelname,
          token,
          selectedToken,
          // id: props.location.state.recommendationData?.hotel?.id,
          id: props.location.state?.hotelid,
          bookingInfo: {
            billingContact: values,
            rateIds: rateId,
            roomsAllocations: roomAllocation,
          }
        },
      },
    };


    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    let result
    if (!selectedPaymentMethodId) {
      result = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details: {
          address: {
            country: values.billingcountry,
            postal_code: values.billingcode,
            line1: values.billingaddress,
            state: values.billingstate
          },
          email: values.email,
          phone: values.phone,
          name: values.firstName + " " + values.lastName,
        },
      });
    }

    if (result?.error && !selectedPaymentMethodId) {
      // Show error in payment form
      setCardErrorMsg(result.error.message)
    } else {
      if (result?.paymentMethod) {
        config.data.stripe_id = result.paymentMethod.id;
      }

      if (selectedPaymentMethodId) {
        const companyData = JSON.parse(localStorage.getItem("companyData"));
        config.data.customer_stripe_id = companyData.stripeCustomerId
        config.data.stripe_id = selectedPaymentMethodId
      }

      const isTestAcc = getIsTestAcc();

      config.data.isTest = isTestAcc ?? false;

      await Axios(config)
        .then((respose) => {

          const res = respose.data

          // const stripePromise = isTestAcc ? process.env.REACT_APP_STRIPE_KEY_TEST: process.env.REACT_APP_STRIPE_KEY;

          // console.log(respose, stripePromise, isTestAcc)
          // setIsLoading(false)
          // return
          if (res.status && !res.requires_action) {
            window.location.href = `/payment/booking?refId=${res.ref_id}&type=INPROGRESS`


          } else if (res.status && res.requires_action) {
            // stripe action
            window.location.href = `/payment/checkout?secret=${res.payment_intent_client_secret}&refId=${res.ref_id}`
          } else {
            setCardErrorMsg(res.error)
          }
        })
        .catch((err) => {
          alert(
            `Booking Failed. ${err.toString()}. Error-TraceID : ${corelationId}`
          );
        })
        .finally(() => setIsLoading(false));

    }
  }

  const fetchSavedBookingData = async () => {
    const config = {
      method: "get",
      url: `/saved-booking-detail/${saved_booking_id}/get-detail`,
    };

    await Axios(config)
      .then((response) => {
        const guestDetails = JSON.parse(response.data.guest_details)
        setSavedBookingInfo({
          ...response.data,
          guestDetails
        })
        console.log(`${response.data.User.firstName} ${response.data.User.lastName}`)
        // setGuestNames(['ok'])
        setGuestNames([`${response.data.User.firstName} ${response.data.User.lastName}`])

        // if (response?.data) setSavedBookingInfo(response.data)
        // message.success("Booking details has successfully been saved! Please wait for the PIC to book for you.");
      })
      .catch((err) => {
        // alert(
        //   `Failed to save booking`
        // );
      })
      .finally(() => {
        setIsLoading(false)
      });
  }


  const handleSubmit = () => {
    props.form.validateFields((err, values) => {
      let cardErrors = false;
      if (meta.erroredInputs.cardNumber) {
        cardErrors = true;
        props.form.setFields({
          cardNumber: {
            value: values.cardNumber,
            errors: [new Error(meta.erroredInputs.cardNumber)],
          },
        });
      }
      if (meta.erroredInputs.expiryDate) {
        cardErrors = true;
        props.form.setFields({
          expiryDate: {
            value: values.expiryDate,
            errors: [new Error(meta.erroredInputs.expiryDate)],
          },
        });
      }
      if (meta.erroredInputs.cvc) {
        cardErrors = true;
        props.form.setFields({
          cvv: {
            value: values.cvv,
            errors: [new Error(meta.erroredInputs.cvc)],
          },
        });
      }

      if (!err && !cardErrors) {
        if (sameAddress) {
          values.billingcountry = values.country;
          values.billingstate = values.state;
          values.billingcode = values.code;
          values.billingaddress = values.address;
        }

        setIsLoading(true);
        PaymentInit(values);
      }
    });
  };

  const Loading = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Spin size="large" />
    </div>
  );

  const policyModal = () => {
    const rates = props.location.state.recommendationData?.hotel?.rates[0];
    let refundTooltip = '';
    if (rates?.cancellationPolicies?.length) {
      const res = processCancellationPolicy(rates.cancellationPolicies);
      refundTooltip = `<div>${res.shortText}</div>`;
      refundTooltip += res.table;
    }
    return (
      <Modal
        title="Policies"
        visible={policyModalVisible}
        onCancel={() => setpolicyModalVisible(false)}
        closable={false}
        footer={[
          <Button key="back" onClick={() => setpolicyModalVisible(false)}>
            Close
          </Button>,
        ]}
      >
        {refundTooltip.length > 0 && (
          <div style={{ marginBottom: '10px' }}>
            <strong>Refundable Policy:</strong>
            <span dangerouslySetInnerHTML={{ __html: refundTooltip }} />
          </div>
        )}

        {props.location.state.recommendationData?.hotel?.rates[0]?.policies?.map(
          (policy, index) => (
            <div key={index}>
              <strong>{formatPolicyType(policy.type)}:</strong>
              <div style={{ whiteSpace: 'break-spaces' }}>{formatPolicyText(policy.text)}</div>
            </div>
          )
        )}

      </Modal>
    )
  };

  // Multiple Guest Details
  const [guestNames, setGuestNames] = useState(Array.from({ length: 1 }, () => ['']));

  // Function to handle adding a new guest name field
  const addGuestNameField = () => {
    setGuestNames([...guestNames, '']); // Add a new empty guest name
  };

  // Function to handle changes in guest name input
  const handleGuestNameChange = (index, value) => {
    const updatedGuestNames = [...guestNames];
    updatedGuestNames[index] = value;
    setGuestNames(updatedGuestNames);
  };

  const [indosatCompanyPaymentCard, setIndosatCompanyPaymentCard] = useState([])
  const getIndosatCompanyPaymentCardData = async () => {
    const company = JSON.parse(localStorage?.getItem("companyData"))

    if (company) {
      let config = {
        method: "get",
        url: `/company-payment/payment-methods/${company.stripeCustomerId}`,
      };

      const res = await Axios(config)
      if (res.status === 200) {
        console.log(res.data)
        setIndosatCompanyPaymentCard(res.data);
      }
    }
  }

  const isAllowedToBookIndosatB2B = CheckIndosatTeamMemberRolesHelper(1002)
  const isAllowedToBookForOtherIndosatB2B = CheckIndosatTeamMemberRolesHelper(1003)
  const isAllowedToUseCompanyCardIndosatB2B = CheckIndosatTeamMemberRolesHelper(1004)

  useEffect(() => {
    getIndosatCompanyPaymentCardData()
    _gtag('add_to_cart');
    if (saved_booking_id) fetchSavedBookingData()

    window.scrollTo(0, 0);
  }, [])

  return (
    <div className="PersonalDetailsContainer">
      {(
        props.location.state.recommendationData?.hotel?.rates[0]?.policies?.length > 0 ||
        props.location.state.recommendationData?.hotel?.rates[0]?.cancellationPolicies?.length > 0)
        && policyModal()}
      <Header isMobile={isMobile} isDarkFont={true} />

      {/* {!isAllowedToBookIndosatB2B ? (
        <>
          <div style={{ textAlign: 'center', padding: '30px 0' }}><h2>Sorry, you do not have the permission to book. Please contact your company PIC </h2></div>
        </>
      ) : (
      )} */}

      <div className="PersonalDetails">
        <div className="bookingHotelInfo">
          <div className="outerBoxSpacer" />

          <div className="outerBox">
            <div className="topheading">
              Booking Summary
              <MediaQuery maxWidth={775}>
                <Icon
                  type={viewMore ? "minus" : "plus"}
                  onClick={() => setviewMore(!viewMore)}
                />
              </MediaQuery>
            </div>

            {viewMore && (
              <div>
                <div
                  style={{
                    backgroundImage: `url(${props.location.state.hotelImage})`,
                  }}
                  className="imageDiv"
                />
                <div
                  style={{
                    color: "black",
                    fontSize: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.location.state.hotelname}
                </div>
                <div
                  style={{
                    color: "black",
                    fontSize: "1.25rem",
                    marginBottom: "1rem",
                  }}
                >
                  {props.location.state.hotelAddress}
                </div>
                <div className="infoBox">
                  <div className="subHeading">Check In</div>
                  <div
                    style={{
                      color: "black",
                      paddingBottom: "1rem",
                      marginBottom: "1rem",
                      borderBottom: "1px solid #E9EDEF",
                    }}
                  >
                    {moment(props.location.state.checkinDate).format("dddd, D MMMM YYYY")}
                    {/* ,{" "} */}
                    {/* {props.location.state.checkinDate} */}
                    {/* at{" "} {props.location.state.checkintime} */}
                  </div>
                  <div className="subHeading">Check out</div>
                  <div style={{ color: "black", marginBottom: "1rem" }}>
                    {moment(props.location.state.checkoutDate).format("dddd, D MMMM YYYY")}
                    {/* ,{" "} */}
                    {/* {props.location.state.checkoutDate} */}
                    {/* at{" "}{props.location.state.checkouttime} */}
                  </div>

                  <div className="flexSpWrap">
                    <div className="flexcol bookingModalInfo">
                      <div className="subHeading">Rooms</div>
                      <div>{props.location.state.Roomcount}</div>
                    </div>

                    <div className="flexcol bookingModalInfo">
                      <div className="subHeading">Nights</div>
                      <div>{props.location.state.nights}</div>
                    </div>

                    <div className="flexcol bookingModalInfo">
                      <div className="subHeading">Adults</div>
                      <div>{props.location.state.guestDetails.noofAdults}</div>
                    </div>

                    {props.location.state.guestDetails.noofChild > 0 && (
                      <div className="flexcol bookingModalInfo">
                        <div className="subHeading">Children</div>
                        <div>{props.location.state.guestDetails.noofChild}</div>
                      </div>
                    )}
                    {props.location.state.guestDetails.noofChild > 0 && (
                      <div className="flexcol bookingModalInfo">
                        <div className="subHeading">Age</div>
                        <div>{props.location.state.guestDetails.ages}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="subHeading">Total (Including all taxes)</div>
                  <div style={{ color: "#F48247", marginBottom: "1rem" }}>
                    {localStorage.getItem("currency")}{" "}
                    {props.location.state.rateDetails
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </div>
                </div>
                {(props.location.state.recommendationData?.hotel?.rates[0]?.policies?.length > 0 ||
                  props.location.state.recommendationData?.hotel?.rates[0]?.cancellationPolicies?.length > 0) && (
                    <Button
                      type="link"
                      style={{ color: "var(--vdOrange", padding: 0 }}
                      onClick={() => setpolicyModalVisible(true)}
                    >
                      View Policy
                    </Button>
                  )}
              </div>
            )}
          </div>
        </div>
        <div className="bookingDetailsContainer">
          <div className="mainHeading">Personal Details</div>
          <div className="bookingDetails">
            <div className="inputContainer firstname">
              <Form.Item style={{ margin: 0 }}>
                {getFieldDecorator("firstName", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your name",
                    },
                    {
                      type: "string",
                      message: "Please input a valid name",
                    },
                  ],
                  initialValue: `${JSON.parse(localStorage.getItem("userdata")).first_name
                    }`,
                })(
                  <Input
                    style={{ border: "none", width: "100%" }}
                    placeholder="First Name"
                  />
                )}
              </Form.Item>
            </div>
            <div className="inputContainer lastname">
              <Form.Item style={{ margin: 0 }}>
                {getFieldDecorator("lastName", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your name",
                    },
                    {
                      type: "string",
                      message: "Please input a valid name",
                    },
                  ],
                  initialValue: `${JSON.parse(localStorage.getItem("userdata")).last_name
                    }`,
                })(
                  <Input
                    style={{ border: "none", width: "100%" }}
                    placeholder="Name"
                  />
                )}
              </Form.Item>
            </div>

            <div
              className="inputContainer"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Form.Item style={{ margin: 0, width: "100%" }}>
                {getFieldDecorator("phone", {
                  rules: [
                    {
                      required: true,
                      message: "Invalid Phone Number",
                    },
                  ],
                  initialValue: JSON.parse(localStorage.getItem("userdata")).contactNumber ?? initPaymentValue.phone,
                })(
                  <PhoneInput
                    onChange={(phoneData, country) => autoSetCountryName(phoneData, country)}
                    inputClass="profiletelephoneinput"
                    containerClass="profiletelephoneinput"
                    dropdownStyle={{ border: "none" }}
                    placeholder="Phone"
                  />
                )}
              </Form.Item>
            </div>

            <div className="inputContainer">
              <Form.Item style={{ margin: 0 }}>
                {getFieldDecorator("email", {
                  rules: [
                    {
                      required: true,
                      message: "Please enter your email",
                    },
                    {
                      type: "email",
                      message: "Please input a valid email",
                    },
                  ],
                  initialValue: `${localStorage.getItem("useremail")}`,
                })(
                  <Input
                    style={{ border: "none", width: "100%" }}
                    placeholder="Email"
                  />
                )}
              </Form.Item>
            </div>

            <div className="inputContainer">
              <Form.Item style={{ margin: 0 }}>
                {getFieldDecorator("age", {
                  rules: [
                    { type: "integer", required: true, message: "Invalid Age" },
                  ],
                  initialValue: initPaymentValue.age
                })(
                  <InputNumber
                    style={{ border: "none", width: "100%" }}
                    placeholder="Age"
                  />
                )}
              </Form.Item>
            </div>
          </div>

          {/* Address */}
          <div className="bookingDetails" style={{ marginBottom: 0 }}>
            <div className="inputContainer">
              <Form.Item style={{ margin: 0 }}>
                {getFieldDecorator("address", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your address",
                    },
                  ],
                  initialValue: initPaymentValue.address
                })(
                  <Input
                    style={{ border: "none", width: "100%" }}
                    placeholder="Address"
                  />
                )}
              </Form.Item>
            </div>

            <div className="doubleInput">
              <div
                className="inputContainer"
                style={{ width: "auto", marginRight: "1rem" }}
              >
                <Form.Item style={{ margin: 0 }}>
                  {getFieldDecorator("code", {
                    rules: [
                      {
                        required: true,
                        message: "Invalid postal code",
                      },
                    ],
                    initialValue: initPaymentValue.code
                  })(
                    <Input
                      style={{ border: "none", width: "100%" }}
                      placeholder="Postal Code"
                    />
                  )}
                </Form.Item>
              </div>

              <div className="inputContainer" style={{ width: "auto" }}>
                <Form.Item style={{ margin: 0 }}>
                  {getFieldDecorator("city", {
                    rules: [
                      {
                        type: "string",
                        required: true,
                        message: "Please enter your city",
                      },
                    ],
                    initialValue: initPaymentValue.city
                  })(
                    <Input
                      style={{ border: "none", width: "100%" }}
                      placeholder="City"
                    />
                  )}
                </Form.Item>
              </div>
            </div>

            <div className="inputContainer">
              <Form.Item style={{ margin: 0 }}>
                {getFieldDecorator("country", {
                  rules: [
                    {
                      required: true,
                      message: "Please enter your country",
                    },
                  ],
                  // initialValue: initPaymentValue.country
                })(
                  <CountrySelector
                    style={{ border: "none", width: "100%" }}
                    // disabled
                    onChange={(value) => {
                      props.form.setFieldsValue({
                        country: value,
                        state: undefined,
                      });
                    }}
                  />
                )}
              </Form.Item>
            </div>

            <div className="inputContainer">
              <Form.Item style={{ margin: 0 }}>
                {getFieldDecorator("state", {
                  rules: [
                    {
                      required: true,
                      message: "Please enter your state",
                    },
                  ],
                })(
                  <RegionSelector
                    style={{ border: "none", width: "100%" }}
                    // disabled={!!!props.form.getFieldValue("country")}
                    country={props.form.getFieldValue("country")}
                  // disabled
                  />
                )}
              </Form.Item>
            </div>
          </div>
          {/* closing personal details */}

          {
            ((isAllowedToBookForOtherIndosatB2B || (saved_booking_id && savedBookingInfo))) && (
              <div>
                <div className="mainHeading" style={{ marginTop: "2rem", marginBottom: "2rem" }}>
                  Guest Details
                </div>
                <p>If you are booking for someone else, please enter the guest's name here.</p>
                <div>
                  <div style={{ marginBottom: '2rem' }}>
                    {/* Render existing guest name fields */}
                    {guestNames.map((guestName, index) => (
                      // <Input
                      //   key={index}
                      //   placeholder={`Guest name for Room ${index + 1}`}
                      //   value={guestName}
                      //   onChange={(e) => handleGuestNameChange(index, e.target.value)}
                      // />
                      <div className="inputContainer" style={{ width: '100%' }}>
                      <Form.Item style={{ margin: 0 }}>
                        {getFieldDecorator(`Guest name ${index + 1}`, {
                          rules: [
                            // Remove the 'required' rule to allow empty values
                            // {
                            //   required: true,
                            //   message: `Please input guest name ${index + 1}`,
                            // },
                          ],
                          initialValue: guestName ?? '',
                        })(
                          <Input
                            style={{ border: "none", width: "100%" }}
                            placeholder={`Guest name ${index + 1}`}
                            onChange={(e) => handleGuestNameChange(index, e.target.value)}
                            disabled={saved_booking_id && index == 0}
                          />
                        )}
                      </Form.Item>
                    </div>
                    ))}
                  </div>

                  <button className="btn-primary" style={{ width: '100%', opacity: '100%', backgroundColor: '#d93b4f', outline: 'none', border: 'none', }} onClick={addGuestNameField}>Add Guest Name</button>
                </div>
              </div>
            )
          }

          {creditCardRequired && (
            <div>
              <div className="mainHeading" style={{ marginTop: "2rem", marginBottom: "2rem" }}>
                Credit Card Details
              </div>
              <div style={{ marginBottom: '2rem' }}>
                <span style={{ color: 'red' }}>{cardErrorMsg}</span>
                <CardElement onChange={(e) => e.complete && !e.error ? setCardValidation(true) : setCardValidation(false)} />
                {/*<CardNumberElement/>*/}
                {/*<CardExpiryElement/>*/}
                {/*<CardCvcElement/>*/}
              </div>
            </div>
          )}

          {
            isAllowedToUseCompanyCardIndosatB2B && (
              <div>
                {indosatCompanyPaymentCard?.length > 0 && <h2>Select Company's Saved Card</h2>}
                {indosatCompanyPaymentCard.map((method) => (
                  <div key={method.id}>
                    <input
                      type="radio"
                      value={method.id}
                      checked={selectedPaymentMethodId === method.id}
                      onChange={(e) => {
                        setSelectedPaymentMethodId(method.id)
                      }}
                    />
                    <label style={{ marginLeft: '10px' }}>{method.card.brand} ending in {method.card.last4}</label>
                  </div>
                ))}
              </div>
            )
          }
          <div>
            <Checkbox
              checked={sameAddress}
              onChange={() => setsameAddress(!sameAddress)}
              style={{ marginTop: "2rem", marginBottom: "1rem" }}
            >
              Billing address is the same as shipping address
            </Checkbox>
          </div>
          {/* Billing address */}
          {!sameAddress && (
            <div className="bookingDetails">
              <div className="inputContainer">
                <Form.Item style={{ margin: 0 }}>
                  {getFieldDecorator("billingaddress", {
                    rules: [
                      {
                        required: true,
                        message: "Please input your billing address",
                      },
                    ],
                  })(
                    <Input
                      style={{ border: "none", width: "100%" }}
                      placeholder="Billing address"
                    />
                  )}
                </Form.Item>
              </div>

              <div className="doubleInput">
                <div
                  className="inputContainer"
                  style={{ width: "auto", marginRight: "1rem" }}
                >
                  <Form.Item style={{ margin: 0 }}>
                    {getFieldDecorator("billingcode", {
                      rules: [
                        {
                          required: true,
                          message: "Invalid postal code",
                        },
                      ],
                    })(
                      <Input
                        style={{ border: "none", width: "100%" }}
                        placeholder="Postal Code"
                      />
                    )}
                  </Form.Item>
                </div>

                <div className="inputContainer" style={{ width: "auto" }}>
                  <Form.Item style={{ margin: 0 }}>
                    {getFieldDecorator("billingcity", {
                      rules: [
                        {
                          type: "string",
                          required: true,
                          message: "Please enter the billing city",
                        },
                      ],
                    })(
                      <Input
                        style={{ border: "none", width: "100%" }}
                        placeholder="Billing City"
                      />
                    )}
                  </Form.Item>
                </div>
              </div>

              <div className="inputContainer">
                <Form.Item style={{ margin: 0 }}>
                  {getFieldDecorator("billingcountry", {
                    rules: [
                      {
                        required: true,
                        message: "Please enter the billing country",
                      },
                    ],
                  })(
                    <CountrySelector
                      placeholder="Select the billing country"
                      style={{ border: "none", width: "100%" }}
                      onChange={(value) => {
                        props.form.setFieldsValue({
                          billingcountry: value,
                          billingstate: undefined,
                        });
                      }}
                    />
                  )}
                </Form.Item>
              </div>

              <div className="inputContainer">
                <Form.Item style={{ margin: 0 }}>
                  {getFieldDecorator("billingstate", {
                    rules: [
                      {
                        required: true,
                        message: "Please enter your billing state",
                      },
                    ],
                  })(
                    <RegionSelector
                      placeholder="Select the billing state"
                      style={{ border: "none", width: "100%" }}
                      disabled={!!!props.form.getFieldValue("billingcountry")}
                      country={props.form.getFieldValue("billingcountry")}
                    />
                  )}
                </Form.Item>
              </div>
            </div>
          )}
          <div>
            <Checkbox
              checked={termsAndConditions}
              onChange={() => settermsAndConditions(!termsAndConditions)}
              style={{ marginBottom: "4rem" }}
            >
              Agree to terms and conditions
            </Checkbox>

          </div>
          {isLoading &&
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginRight: "1rem" }}
              disabled={true}
            >
              Loading...
            </Button>
          }
          {!isLoading &&
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginRight: "1rem" }}
              onClick={handleSubmit}
              // disabled={(!termsAndConditions || !cardValidation) && !selectedPaymentMethodId}
            >
              Book
            </Button>
          }

        </div>
      </div>

      {
        saved_booking_id && (

          <div style={{
            position: 'fixed',
            bottom: '50px',
            left: '50px',
            right: '50px',
            zIndex: 10,
            backgroundColor: 'rgba(252, 218, 78, 0.8)',
            padding: '10px',
            borderRadius: '10px',
          }}>
            You are currently booking for {userData.id == savedBookingInfo?.User.id ? 'yourself' : `${savedBookingInfo?.User?.firstName} ${savedBookingInfo?.User?.lastName}`}
            <br />Notes: "{savedBookingInfo?.notes}"
          </div>
        )
      }

      <FooterPage />
    </div>
  );
};

const CheckoutPage = Form.create({ name: "PersonalDetails" })(CheckoutPageForm);

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const isTestAcc = true;
const isTestAcc = getIsTestAcc();
const stripePromise = isTestAcc ? loadStripe(`${process.env.REACT_APP_STRIPE_KEY_TEST}`) : loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

const StripeProvider = (props) => {

  return (
    <Elements stripe={stripePromise}>
      <CheckoutPage {...props} />
    </Elements>
  )
}

export default StripeProvider;
