import React, { useEffect, useState } from "react";
import { Rate, Icon, Button, message } from "antd";
import { HotelDescription } from "components/Hotel/HotelDescription/HotelDescription";
import { Link, } from "react-router-dom";
import SearchBar from "../../components/SearchBar/SearchBar";
import { IoIosArrowUp } from "react-icons/io";
import ScrollTop from "react-scrolltop-button";
import ImageGallery from "react-image-gallery";
import Modal from "react-modal";
import moment from "moment";
import { FaStar } from "react-icons/fa";
import Axios from "axios";
import { getCurrency, _defaultHeaders } from "helpers/AxiosHelper";
import FooterPage from "components/Global/FooterPage/FooterPage";
import HotelLocation from "components/Hotel/HotelLocation/HotelLocation";
import Landmarks from "components/Hotel/Landmarks/Landmarks";

import RoomsAndRates from "components/Hotel/RoomsAndRates/RoomsAndRates";
import SaveRoomModal from "components/Hotel/SaveRoomModal/SaveRoomModal";

const ShowMore = ({ state, setState }) => (
  <Link
    to="#"
    onClick={(e) => {
      e.preventDefault();
      setState(!state);
    }}
    style={{ color: "#F48247" }}
  >
    {state ? "View less" : "Show more"}
  </Link>
);

export const AccommodationDetailsDesktop = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenSaveBookingModal, setIsOpenSaveBookingModal] = useState(false);
  const [saveBookingRoomType, setSaveBookingRoomType] = useState(null);
  const [about, setabout] = useState(false);
  const [modal, setmodal] = useState(false);
  const [galleryStart, setgalleryStart] = useState(0);
  const [savedBookingInfo, setSavedBookingInfo] = useState(null)
  const { data } = props;
  const { images, name, contact, starRating } = data;
  const userData = JSON.parse(localStorage.getItem("userdata"));
  const companyData = JSON.parse(localStorage.getItem("companyData"));

  const days = Math.round(
    (Date.parse(props.location.state.endDate) -
      Date.parse(props.location.state.startDate)) /
    (1000 * 60 * 60 * 24)
  );

  const imagesArray = images.map((image) => {
    return {
      original:
        image.links[1] && image.links[1].url
          ? image.links[1].url
          : image.links[0].url,
      thumbnail:
        image.links[1] && image.links[1].url
          ? image.links[1].url
          : image.links[0].url,
      description: image.caption,
    };
  });

  const showImages = [false, false, false, false];
  const queryString = props?.history?.location?.search;
  const queryParams = {};
  queryString.slice(1).split('&').forEach(query => {
    const [key, value] = query.split('=');
    queryParams[key] = value;
  });
  const search_timestamp = queryParams.d;
  const place_id = queryParams.p_id;
  const saved_booking_id = queryParams.saved_booking_id;

  const getSavedBookingInfo = async () => {
    if (!userData) return;


    let data = props.data;
    const config = {
      method: "get",
      url: `/saved-booking-detail/get-detail?companyId=${companyData?.id}&userId=${userData?.id}&hotel_id=${data?.id}&place_id=${place_id}&search_timestamp=${search_timestamp}`,
    };

    await Axios(config)
      .then((response) => {
        if (response?.data) {
          const guestDetails = JSON.parse(response.data.guest_details)

          setSavedBookingInfo({
            ...response.data, guestDetails
          })
          // setSavedBookingInfo(response.data)
        }
        // message.success("Booking details has successfully been saved! Please wait for the PIC to book for you.");
      })
      .catch((err) => {
        // alert(
        //   `Failed to save booking`
        // );
      })
      .finally(() => {
        // setIsLoading(false)
      });
  }

  // console.log(props.location.state,  props.carryForward.correlationId)


  const handleSaveBooking = async () => {
    // console.log(props.location.state)
    // return

    const locationInfo = props.location.state
    // const state = {
    //   correlationId: props.history.correlationId,
    //   place: props.place,
    //   placeId: props.placeId,
    //   activeSuggestion: props.activeSuggestion,
    //   startDate: props.startDate, //
    //   endDate: props.endDate, //
    //   noofAdults: props.noofAdults, //
    //   noofChild: props.noofChild, // 
    //   noofroom: props.noofroom, //
    //   lattitude: props.geoCode.lat,
    //   longittude: props.geoCode.long,
    //   panes: props.location.panes,
    // };
    const userData = JSON.parse(localStorage.getItem("userdata"));
    const companyData = JSON.parse(localStorage.getItem("companyData"));

    const hotelAddress = props.data.contact.address
    let locState = props.carryForward;


    let data = props.data;
    const config = {
      method: "post",
      url: 'saved-booking-detail/save',
      headers: _defaultHeaders(
        getCurrency(),
        props.carryForward.correlationId
      ),
      data: {
        companyId: companyData.id,
        teamMemberId: '1',
        userId: userData.id,

        correlationId: locationInfo.correlationId,
        latitude: locationInfo.lattitude,
        longitude: locationInfo.longittude,
        place: locationInfo.place,

        search_timestamp,
        place_id,
        hotel_id: data.id,
        hotel_name: props.data.name,
        hotel_image: props.data.heroImage,

        destination: `${hotelAddress.city.name}, ${hotelAddress.country.name}`,
        checkin_date: locState.startDate,
        checkout_date: locState.endDate,
        notes: saveBookingRoomType,
        guest_details: JSON.stringify({
          noofAdults: locState.noofAdults,
          noofChild: locState.noofChild,
          panes: locState.panes,
          ages: props.childrenInfoString(true),
        }),
        room_count: locState.noofroom,
      },
    };

    await Axios(config)
      .then((response) => {
        getSavedBookingInfo()
        setSaveBookingRoomType(null)
        setIsOpenSaveBookingModal(false)
        message.success("Booking details has successfully been saved! Please wait for the PIC to book for you.");
        setTimeout(() => {
          window.location.reload()
        }, 1000);
      })
      .catch((err) => {
        // alert(
        //   `Failed to save booking`
        // );
      })
      .finally(() => {
        // setIsLoading(false)
      });

  }

  if (images.length > 0) {
    const len = images.length > 4 ? 4 : images.length
    for (let i = 0; i < len; i++) {
      showImages[i] =
        !!images[i].links[1] && !!images[i].links[1].url
          ? images[i].links[1].url
          : images[i].links[0].url;
    }
  }

  const openModal = (value) => {
    setgalleryStart(value);
    setmodal(true);
  };


  if (data.facilities.length % 2 !== 0) {
    data.facilities.push(null)
  }

  useEffect(() => {
    getSavedBookingInfo()
  }, [])

  // const location = useLocation()
  // console.log(props.location, props)
  // return
  // console.log(props.location.state.panes[0], props)

  const fetchSavedBookingData = async () => {
    if (saved_booking_id) {
      const config = {
        method: "get",
        url: `/saved-booking-detail/${saved_booking_id}/get-detail`,
      };

      await Axios(config)
        .then((response) => {
          // history={props.history}
          // place={props.location.state.place}
          // startDate={props.location.state.startDate}
          // endDate={props.location.state.endDate}
          // panes={props.location.state.panes[0]}
          // totalAdults={props.location.state.noofAdults}
          // totalChildren={props.location.state.noofChild}
          // totalRooms={props.location.state.noofroom}
          const guestDetails = JSON.parse(response.data.guest_details)
          setSavedBookingInfo({
            ...response.data, guestDetails
          })

          // console.log(response)
          // console.log(guestDetails)
          props.location.state.startDate = response.data.checkin_date
          props.location.state.endDate = response.data.checkout_date
          props.location.state.totalRooms = response.data.room_count
          props.location.state.panes = guestDetails.panes
          props.location.state.totalAdults = guestDetails.noofAdults
          props.location.state.totalChildren = guestDetails.noofChild

          // console.log(props.location.state.panes[0])
          // console.log(response)
          // if (response?.data) setSavedBookingInfo(response.data)
          // message.success("Booking details has successfully been saved! Please wait for the PIC to book for you.");
        })
        .catch((err) => {
          // alert(
          //   `Failed to save booking`
          // );
        })
        .finally(() => {
          setIsLoading(false)
        });
    }

  }


  const removeBooking = async ({
    companyId,
    userId,
    hotel_id,
    place_id,
  }) => {
    const config = {
      method: 'post',
      url: 'saved-booking-detail/remove',
      // headers: _defaultHeaders(getCurrency(), props.carryForward.correlationId),
      data: {
        companyId,
        userId,
        hotel_id,
        place_id,
      },
    };

    await Axios(config)
      .then((response) => {
        getSavedBookingInfo();
        message.success('Booking has been successfully removed.');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        console.error('Error removing booking:', err);
        message.error('Failed to remove booking.');
      })
      .finally(() => {
        // Perform any additional cleanup actions here if needed
      });
  };

  useEffect(() => {
    fetchSavedBookingData()
    // if (Object.keys(props.location.state).length == 0 || saved_booking_id) {
    //   //   setIsLoading(true)
    //   fetchSavedBookingData()
    // }
  }, [])

  // console.log(props)


  if (Object.keys(props.location.state).length == 0) {
    return <>
    </>
  } else {
    return (
      <div className="AccommodationDetailsDesktop">
        <SaveRoomModal
          isOpen={isOpenSaveBookingModal}
          setIsOpen={setIsOpenSaveBookingModal}
          roomType={saveBookingRoomType}
          setRoomType={setSaveBookingRoomType}
          submitAction={handleSaveBooking}
          hotelDatas={props.data}
          currentData={savedBookingInfo}
          removeBooking={removeBooking}
        // getSavedBookingInfo={getSavedBookingInfo}
        // childrenInfoString={props.childrenInfoString}
        // data={props.data}
        // carryForward={props.carryForward}
        />
        <Modal isOpen={modal}>
          <div
            style={{ display: "flex", justifyContent: "flex-end", zIndex: 1000 }}
          >
            <Icon
              type="close-circle"
              style={{ fontSize: "3rem", marginBottom: "1rem" }}
              onClick={() => setmodal(false)}
            />
          </div>
          <ImageGallery
            items={imagesArray}
            showFullscreenButton={false}
            showPlayButton={false}
            startIndex={galleryStart}
          />
        </Modal>

        {props.bookingModal}
        {props.policyModal()}

        {props.header}
        <ScrollTop
          style={{ right: '7%' }}
          icon={<IoIosArrowUp />}
          className="scrollToTopClass"
          text={<IoIosArrowUp />}
        />
        <div className="acccontainer">
          <div
            style={{
              display: "flex",
              height: "60vh",
              justifyContent: "space-between",
              margin: "3rem 0",
            }}
          >
            <div
              style={{ backgroundImage: `url( ${showImages[0]} )` }}
              className="mainImage"
              onClick={() => openModal(0)}
              value={0}
            />
            <div
              style={{
                display: "flex",
                height: "60vh",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <div
                style={{ backgroundImage: `url( ${showImages[1]} )` }}
                className="sideImage"
                onClick={() => openModal(1)}
                value={1}
              />
              <div
                style={{ backgroundImage: `url( ${showImages[2]} )` }}
                className="sideImage"
                onClick={() => openModal(2)}
                value={2}
              />
              <div
                style={{ position: "relative", cursor: "pointer" }}
                onClick={() => openModal(3)}
              >
                <div
                  style={{ backgroundImage: `url( ${showImages[3]} )` }}
                  className="sideImage"
                  value={3}
                />

                <div className="imageOverlay">
                  <div
                    style={{
                      margin: "auto",
                      fontWeight: "bold",
                      fontSize: "3rem",
                      color: "white",
                    }}
                  >
                    +{data.imageCount - 4}
                  </div>
                </div>
              </div>
            </div>
          </div>


          {!saved_booking_id && (
            <SearchBar
              history={props.history}
              place={props.location.state.place}
              startDate={props.location.state.startDate}
              endDate={props.location.state.endDate}
              panes={props.location.state.panes[0]}
              totalAdults={props.location.state.noofAdults}
              totalChildren={props.location.state.noofChild}
              totalRooms={props.location.state.noofroom}
            />
          )}

          <div className="information">
            <div style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              columnGap: '20px'
            }}>
              <div className="mainHeading">{name}</div>

              {companyData && !saved_booking_id && (
                <Button style={{
                  backgroundColor: (savedBookingInfo == null) ? 'grey' : 'red',
                }} className={'roundedButton'}
                  onClick={() => setIsOpenSaveBookingModal(true)}
                >
                  <FaStar style={{
                    color: 'white',
                    marginLeft: '-7px',
                  }} />
                </Button>
              )}

            </div>
            <div className="grayInfo">
              {contact.address.city.name}, {contact.address.country.name}
            </div>
            <div>
              <div className="roomInfo">
                <div className="SearchResultsRoomType">{data.type}</div>
                <Rate
                  disabled={true}
                  value={Number(starRating)}
                  className="Searchstars"
                />
              </div>

              {
                !saved_booking_id && (
                  <>
                    <div style={{ marginTop: "1rem" }}>
                      <div className="subHeading">About the Hotel</div>
                      <div
                        className="grayInfo"
                        style={
                          about
                            ? { height: "auto" }
                            : { height: "15rem", overflow: "hidden" }
                        }
                      >
                        <HotelDescription descriptions={data.descriptions} />
                        <div className="subHeading" style={{ color: 'black' }}>Facilities :</div>
                        {data.facilities.length > 0 &&
                          <div style={{ display: "flex", flexWrap: 'wrap', marginLeft: '-10px' }}>
                            {data.facilities.map((faci, index) => <div style={{ flex: '1 0 0%' }}>
                              <div className="Facilities" style={{ height: 75, width: '18vw' }} key={index}>
                                {" "}
                                {faci &&
                                  <>
                                    <span>{faci.name}</span>
                                    <Icon style={{ color: "#F48247" }} type="check" />
                                  </>
                                }
                              </div>
                            </div>)}
                          </div>
                        }
                      </div>
                      {<ShowMore state={about} setState={setabout} />}
                    </div>

                    <div style={{ marginTop: "1rem" }}>
                      <div className="subHeading">Location and Landmarks</div>

                      <Landmarks
                        nearByAttractions={data.nearByAttractions}
                        number={8}
                      />
                    </div>

                    <div style={{ marginTop: "1rem" }}>
                      <HotelLocation
                        name={name}
                        geoCode={data.geoCode}
                        address={data.contact.address}
                      />
                    </div>
                  </>
                )
              }

              <div style={{ marginTop: "1rem" }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div className="subHeading">Booking details</div>
                </div>

                <div
                  style={{
                    padding: "1rem",
                    border: "1px solid #E9EDEF",
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "none",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "50%",
                    }}
                  >
                    <div style={{ color: "#858586" }}>Check in</div>
                    <div>

                      {moment(props.location.state.startDate).format("dddd, DD MMMM YYYY")}
                      {/* {moment(props.location.state.startDate).format("dddd")},{" "} */}
                      {/* {props.location.state.startDate} at {" "}
                      {data.checkinInfo.beginTime} */}
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      borderLeft: "1px solid #E9EDEF",
                      paddingLeft: "1rem",
                      width: "50%",
                    }}
                  >
                    <div style={{ color: "#858586" }}>Check out</div>
                    <div>
                      {moment(props.location.state.endDate).format("dddd, DD MMMM YYYY")}
                      {/* {moment(props.location.state.endDate).format("dddd")},{" "} */}
                      {/* {props.location.state.endDate}
                      at {data.checkoutInfo.time} */}
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    padding: "1rem",
                    border: "1px solid #E9EDEF",
                    marginBottom: "2rem",
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",

                      marginRight: "auto",
                    }}
                  >
                    <div style={{ color: "#858586" }}>Rooms</div>
                    <div>{props.location.state.noofroom ?? savedBookingInfo.room_count}</div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginRight: "auto",
                      borderLeft: "1px solid #E9EDEF",
                      paddingLeft: "1rem",
                    }}
                  >
                    <div style={{ color: "#858586" }}>Nights</div>
                    <div>{days}</div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      borderLeft: "1px solid #E9EDEF",
                      paddingLeft: "1rem",
                      marginRight: "auto",
                    }}
                  >
                    <div style={{ color: "#858586" }}>Adults</div>
                    <div>{props.location.state.noofAdults ?? savedBookingInfo?.guestDetails?.noofAdults}</div>
                  </div>

                  {(props.location.state.noofChild > 0 || savedBookingInfo?.guestDetails?.noofChild > 0) && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        borderLeft: "1px solid #E9EDEF",
                        paddingLeft: "1rem",
                        marginRight: "auto",
                      }}
                    >
                      <div style={{ color: "#858586" }}>Children</div>
                      <div>{props.location.state.noofChild ?? savedBookingInfo?.guestDetails?.noofChild}</div>
                    </div>
                  )}

                  {(props.location.state.noofChild > 0 || savedBookingInfo?.guestDetails?.noofChild > 0) && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        borderLeft: "1px solid #E9EDEF",
                        paddingLeft: "1rem",
                        marginRight: "auto",
                      }}
                    >
                      <div style={{ color: "#858586" }}>Age</div>
                      <div>{props.childrenInfoString(true)}</div>
                    </div>
                  )}
                </div>
              </div>

              <RoomsAndRates
                hotelId={data.id}
                location={props.location}
                handleSelection={props.handleSelection}
                openPolicyModal={props.openPolicyModal}
                setModal={props.setModal}
                hotelInfo={props.data}
                carryForward={props.carryForward}
                childrenInfoString={props.childrenInfoString}
              />
            </div>
          </div>
        </div>

        {
          saved_booking_id && (

            <div style={{
              position: 'fixed',
              bottom: '50px',
              left: '50px',
              right: '50px',
              zIndex: 10,
              backgroundColor: 'rgba(252, 218, 78, 0.8)',
              padding: '10px',
              borderRadius: '10px',
            }}>
              You are currently booking for {userData.id == savedBookingInfo?.User.id ? 'yourself' : `${savedBookingInfo?.User?.firstName} ${savedBookingInfo?.User?.lastName}`}
              <br />Notes: "{savedBookingInfo?.notes}"
            </div>
          )
        }

        <FooterPage />
      </div>
    );
  }

};
